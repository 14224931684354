// Check if decision was remembered and if yes replace all map wrappers with the map.
if (localStorage.getItem('map-consent') === 'true') {
  replaceAllMapPlaceholder();
}
else {
  document.querySelectorAll('.map-wrapper').forEach(function(mapWrapper, index) {
    let consentButton = mapWrapper.querySelector('.consent-btn');
    let consentWrapper = mapWrapper.querySelector('.map-consent-wrapper');
    let consentCheckbox = mapWrapper.querySelector('.remember-decision');
    let ratioHelper = mapWrapper.querySelector('.ratio-helper');

    // Abort default click handler if click happens on invisible elements.
    consentWrapper.querySelectorAll('*').forEach(function(element) {
      element.addEventListener('click', function () {
        let visibility = getComputedStyle(consentWrapper, null).getPropertyValue('opacity');
        if (visibility < 1) {
          event.preventDefault();
        }
      })
    });

    consentButton.addEventListener('click', function() {
      // Abort consent if click happens on invisible consent button.
      let buttonVisibility = getComputedStyle(consentWrapper, null).getPropertyValue('opacity');
      if (buttonVisibility < 1) {
        // return;
      }

      // User has agreed. Remove placeholder and replace with iframe.
      ratioHelper.innerHTML = getMapFrame(mapWrapper);

      // If consentCheckbox is checked, remember decision
      if (consentCheckbox.checked) {
        localStorage.setItem('map-consent', true);
        // Apply to all map-wrapper divs
        replaceAllMapPlaceholder();
      }
    });
  });
}

/**
 * Generates a Map frame based on the provided Map wrapper element.
 *
 * @param {HTMLElement} mapWrapper - The Map wrapper element containing the map URL in the data-url attribute.
 * @return {string} - The generated Map frame.
 */
function getMapFrame(mapWrapper) {
  let mapFrame = document.createElement('iframe');
  mapFrame.classList.add('map-frame');
  mapFrame.width = '600';
  mapFrame.height = '450';
  mapFrame.src = mapWrapper.getAttribute('data-url');
  mapFrame.style.border = '0';
  mapFrame.allowfullscreen = '';
  mapFrame.loading = 'lazy';
  mapFrame.referrerpolicy = 'no-referrer-when-downgrade';

  return mapFrame.outerHTML;
}

/**
 * Replaces all Map placeholders in the document with Map iframe elements.
 *
 * @function replaceAllMapPlaceholder
 * @returns {void}
 */
function replaceAllMapPlaceholder() {
  document.querySelectorAll('.map-wrapper').forEach(function(mapWrapper) {
    let ratioHelper = mapWrapper.querySelector('.ratio-helper');
    ratioHelper.innerHTML = getMapFrame(mapWrapper);
  });
}